import { cloneDeep, set, get, isNil } from 'lodash';
import { paths } from './lodashUtils';
// Because backend sometimes send null fields, while form (formik) and react input (value) should rather be '' than null
// We want to avoid this warning: Warning: `value` prop on `textarea` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
// Also, we know we should use different typings for form and api dtos and actually dto typings are slightly incorrect, but Dara does not want to maintain a mapping layer between the 2 for now
// So we "fix" the data the backend send us when we need to restore a form, so that it conforms better to its own typing
// Another solution would have been to fallback to '' when null is received in text inputs...
export const fixBackendNullFieldsHack = <T extends {}, U extends Partial<T>>(
  backendForm: T,
  emptyForm: U,
) => {
  const result = cloneDeep(backendForm);
  paths(emptyForm).map(path => {
    const backendValue = get(backendForm, path);
    const emptyValue = get(emptyForm, path);
    if (isNil(backendValue) && !isNil(emptyValue)) {
      set(result, path, emptyValue);
    }
  });
  return result;
};
