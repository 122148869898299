import React from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H1 } from 'primitives/appTitle';
import HeroTitle from 'components/hero/heroTitle';

import { EmptyJoinFormStatics } from './ProspectPhotographerDTOValidation';
import { JoinPageTranslations } from './joinPageTranslations';
import { ShText } from '@shoootin/translations';
import JoinForm from './joinForm';
import { editPhotographerApplication } from '../../appAPI';
import { fixBackendNullFieldsHack } from '../../utils/fixFormUtils';
import { useAsync } from 'react-async-hook';
import { usePageParams } from '../../layout/appContext';
import { graphql } from 'gatsby';
import { EditApplicationPageQuery } from '../../appGraphQLTypes';
import { getFluidImage } from '../../appGatsbyImageUtils';
import { ShSpinner } from '@shoootin/components-web';

const fetchJoinFormValues = async (applicationId: string) => {
  const result = await editPhotographerApplication(applicationId);
  const fixedResult = fixBackendNullFieldsHack(result, EmptyJoinFormStatics);
  console.debug('fetchJoinFormValues', { initial: result, fixed: fixedResult });
  return fixedResult;
};

const JoinFormLoader = ({ applicationId }: { applicationId: string }) => {
  const joinFormAsync = useAsync(fetchJoinFormValues, [applicationId]);
  return (
    <>
      {joinFormAsync.loading && <ShSpinner />}
      {joinFormAsync.error && (
        <div
          // TOimprove how to display join form loading errors?
          style={{ color: 'red' }}
        >
          Technical error
          <div onClick={() => joinFormAsync.execute(applicationId)}>Retry?</div>
        </div>
      )}
      {joinFormAsync.result && (
        <JoinForm initialValues={joinFormAsync.result} />
      )}
    </>
  );
};

const EditApplicationPage = ({ data }: { data: EditApplicationPageQuery }) => {
  const heroImage = getFluidImage(data.heroImage!);
  const { applicationId } = usePageParams();
  return (
    <MainLayout
      className="page-photograph"
      showContact={false}
      pageName="photographerJoinEdit"
    >
      <HeroTitle imgSrc={heroImage}>
        <H1 modifier="hero-title">
          <ShText message={JoinPageTranslations.pageTitleEdit} />
        </H1>
      </HeroTitle>
      {applicationId ? (
        <JoinFormLoader applicationId={applicationId} />
      ) : (
        <ShSpinner />
      )}
    </MainLayout>
  );
};

export default EditApplicationPage;

export const query = graphql`
  query EditApplicationPage {
    heroImage: file(relativePath: { eq: "photographer_header.jpg" }) {
      ...AppFluidHeroImageFragment
    }
  }
`;
